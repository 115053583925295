import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../types';
import Page from '../components/page';
import AppBar from '../components/appBar';
import main from '../main';
import { delay, formatNumber } from '../utils/utils';
import DefaultLoading from '../components/defaultPage/defaultLoading';
import Button from '../components/button';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CardStatus } from '../utils/dict';

export default function CardCheckPage() {
  const navigate = useNavigate();
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  // 0 未检测 1 检测流量 2 检测套餐 3 检测实名 4 检测卡状态 5 检测完成
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (currentCard.result?.id) {
      startCheck();
    }
  }, [currentCard]); // eslint-disable-line

  async function startCheck() {
    for (let i = 1; i <= 5; i++) {
      await delay(1000);
      setStep(i);
    }
  }

  if (!currentCard.result) {
    return (
      <div className="vw-100 vh-100 pos-r">
        <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
        <Page
          className="d-flex-c pos-a"
          contentClassName="pt-50"
          appBar={<AppBar white className="bg-color-t0" title="智能检测" />}
        >
          <DefaultLoading />
        </Page>
      </div>
    );
  }
  let descTips: ReactNode[] = [];

  let card = currentCard.result;
  // 剩余流量
  let sFlow = card.theMonth?.surplusFlow ?? 0;
  let flowWarning = !!card.theMonth && sFlow < 3;
  if (flowWarning) {
    descTips.push(
      <div>
        {descTips.length + 1}， 您的可用流量不足3G，避免影响您的正常使用，请尽快购买加油包继续使用，推荐
        <span
          className="text-color-primary"
          onClick={() => navigate('/cardProcess', { state: { step: 2, iccid: card.iccid } })}
        >
          去订购加油包
        </span>
      </div>
    );
  }
  // 套餐
  let pack = '';
  if (!!card.theMonth) {
    if (dayjs(card.theMonth.endEffectTime).diff(dayjs(), 'day') <= 6 && !card.nextMonth) {
      pack = `当前有效期不足6天，${dayjs(card.theMonth!.endEffectTime).format('YYYY-MM-DD')}到期`;
      descTips.push(
        <div>
          {descTips.length + 1}， 系统检测，当前您的套餐有效期不足6天且下期无可生效套餐，请及时
          <span
            className="text-color-primary"
            onClick={() => navigate('/cardProcess', { state: { step: 2, iccid: card.iccid, monthType: 'nextMonth' } })}
          >
            订购下期套餐
          </span>
          ，根据需求及时购买，如已订购请忽略
        </div>
      );
    } else {
      pack = `${dayjs(card.theMonth!.startEffectTime).format('YYYY-MM-DD')}至${dayjs(
        card.theMonth!.endEffectTime
      ).format('YYYY-MM-DD')}`;
    }
  } else {
    pack = '当前无生效套餐';
    descTips.push(
      <div>
        {descTips.length + 1}， 您当期暂无可生效套餐，请及时购买本期套餐，继续使用，推荐
        <span
          className="text-color-primary"
          onClick={() => navigate('/cardProcess', { state: { step: 2, iccid: card.iccid } })}
        >
          去订购本期套餐
        </span>
      </div>
    );
  }

  let packWarning = !card.theMonth || (dayjs(card.theMonth.endEffectTime).diff(dayjs(), 'day') <= 6 && !card.nextMonth);
  // 实名
  let realname = card.realStatus === 'Y' ? '已实名' : '当前卡片未实名，网络不可用！';
  if (card.realStatus !== 'Y') {
    descTips.push(
      <div>
        {descTips.length + 1}， 您的卡片未实名，网络不可用，建议您
        <span
          className="text-color-primary"
          onClick={() => navigate('/cardProcess', { state: { step: 3, iccid: card.iccid } })}
        >
          去实名
        </span>
      </div>
    );
  }

  let realnameWarning = card.cardStatus === CardStatus.preRealName;
  // 卡状态
  let cardStatus = CardStatus.getTypeStr(card.cardStatus);
  if (card.cardStatus === CardStatus.stop) {
    cardStatus = card.stopReason ?? '卡片异常';
    descTips.push(
      <div>
        {descTips.length + 1}， 检测到您的卡片异常，建议您
        <span className="text-color-primary" onClick={() => navigate('/customer', { state: { from: '智能检测' } })}>
          联系客服
        </span>
      </div>
    );
    if (!!card.stopReason) {
      descTips.push(<div>异常原因：</div>);
      descTips.push(<div>{card.stopReason}</div>);
    }
  }

  let cardWarning = card.cardStatus !== CardStatus.activated;

  return (
    <div className="vw-100 vh-100 pos-r">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="d-flex-c pos-a"
        contentClassName="flex-1 h-1 overflow-hidden d-flex-c"
        appBar={<AppBar white className="bg-color-t0" title="智能检测" />}
      >
        <div className="pos-r h-144">
          <img className="pos-a z-0 w-144 vml-50 check-bg" src={main.pic('icon_check_bg.png', true)} alt="" />
          {step !== 5 && (
            <img className="pos-a w-63 l-187 t-72 rotate-animation" src={main.pic('icon_check_light.png')} alt="" />
          )}
          <img
            className="pos-a w-35 h-40 vml-50 mr-harf mt-72 check-state"
            src={main.pic(step !== 5 ? 'icon_check_ing.png' : 'icon_check_ok.png', true)}
            alt=""
          />
        </div>
        <div className="text-center text-color-white font-size-16 font-widget-600 mt-10">
          {step !== 5 ? '检测中...' : '检测完成'}
        </div>
        <div className="mh-10 mt-10 bg-color-white border-radius-8 p-10 d-flex-r ai-center d-shadow">
          <img className="w-40 mr-10" src={main.pic('icon_check_flow.png')} alt="" />
          <div className="flex-1">
            <div className="d-flex-r ai-center">
              <div className="font-size-14 font-widget-600 mr-10">剩余流量</div>
              {step > 1 && (
                <img className="w-16" src={main.pic(`icon_check_${flowWarning ? 'warning' : 'success'}.png`)} alt="" />
              )}
              <div className="flex-1"></div>
              {step <= 1 ? (
                <div className="text-color-primary">检测中...</div>
              ) : (
                <div className="font-size-16 font-widget-600">
                  {sFlow > main.showUnlimited ? '不限量' : formatNumber(sFlow)}
                  <span className="font-size-12 ml-3">GB</span>
                </div>
              )}
              {step > 1 && flowWarning && (
                <Button
                  className="ml-5"
                  type="primary"
                  size="sm"
                  shape="circle"
                  onClick={() => navigate('/cardProcess', { state: { step: 2, iccid: card.iccid } })}
                >
                  购买加油包
                </Button>
              )}
            </div>
            {step > 1 && flowWarning && (
              <div className="font-size-12 text-color-9">当期流量不足3G，请根据诉求订购加油包</div>
            )}
          </div>
        </div>
        <div className="mh-10 mt-10 bg-color-white border-radius-8 p-10 d-flex-r ai-center d-shadow">
          <img className="w-40 mr-10" src={main.pic('icon_check_pack.png')} alt="" />
          <div>
            <div className="d-flex-r">
              <div className="font-size-14 font-widget-600 mr-10">套餐情况</div>
              {step > 2 && (
                <img
                  className="w-16 h-16"
                  src={main.pic(`icon_check_${packWarning ? 'warning' : 'success'}.png`)}
                  alt=""
                />
              )}
            </div>
            {step > 2 && <div className="text-color-9 mt-5">{pack}</div>}
          </div>

          <div className="flex-1"></div>
          {step <= 2 ? <div className="text-color-primary">检测中...</div> : undefined}
          {step > 2 && packWarning && (
            <Button
              type="primary"
              size="sm"
              shape="circle"
              onClick={() =>
                navigate('/cardProcess', {
                  state: { step: 2, iccid: card.iccid, monthType: !card.theMonth ? 'currMonth' : 'nextMonth' },
                })
              }
            >
              去购买
            </Button>
          )}
        </div>
        <div className="mh-10 mt-10 bg-color-white border-radius-8 p-10 d-flex-r ai-center d-shadow">
          <img className="w-40 mr-10" src={main.pic('icon_check_realname.png')} alt="" />
          <div>
            <div className="d-flex-r">
              <div className="font-size-14 font-widget-600 mr-10">实名状态</div>
              {step > 3 && (
                <img
                  className="w-16 h-16"
                  src={main.pic(`icon_check_${realnameWarning ? 'warning' : 'success'}.png`)}
                  alt=""
                />
              )}
            </div>
            {step > 3 && <div className="text-color-9 mt-5">{realname}</div>}
          </div>
          <div className="flex-1"></div>
          {step <= 3 ? <div className="text-color-primary">检测中...</div> : undefined}
          {step > 3 && realnameWarning && (
            <Button
              type="primary"
              size="sm"
              shape="circle"
              onClick={() => navigate('/cardProcess', { state: { step: 3, iccid: card.iccid } })}
            >
              去实名
            </Button>
          )}
        </div>
        <div className="mh-10 mt-10 bg-color-white border-radius-8 p-10 d-flex-r ai-center d-shadow">
          <img className="w-40 mr-10" src={main.pic('icon_check_card.png')} alt="" />
          <div>
            <div className="d-flex-r">
              <div className="font-size-14 font-widget-600 mr-10">卡状态</div>
              {step > 4 && (
                <img
                  className="w-16 h-16"
                  src={main.pic(`icon_check_${cardWarning ? 'warning' : 'success'}.png`)}
                  alt=""
                />
              )}
            </div>
            {step > 4 && <div className="text-color-9 mt-5">{cardStatus}</div>}
          </div>
          <div className="flex-1"></div>
          {step <= 4 ? <div className="text-color-primary">检测中...</div> : undefined}
          {step > 4 && cardWarning && (
            <Button
              type="primary"
              size="sm"
              shape="circle"
              onClick={() => navigate('/customer', { state: { from: '智能检测' } })}
            >
              联系客服
            </Button>
          )}
        </div>
        {step > 4 && descTips.length > 0 && (
          <div className="font-size-12 text-color-9 p-10 mt-20">
            <div className="font-size-14 mb-10">检测结果分析</div>
            {descTips}
          </div>
        )}
      </Page>
    </div>
  );
}
