import React from 'react';
import { CurrentCard, DetailRecord } from '../../types';
import main from '../../main';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../../components/dialog';
import { CardStatus } from '../../utils/dict';
interface Props {
  currentCard: DetailRecord<CurrentCard>;
}
export default function HomeCardInfo({ currentCard }: Props) {
  const navigate = useNavigate();
  let color = 'green';
  let status = currentCard?.result?.cardStatus;
  if (status === CardStatus.preRealName || status === CardStatus.waitactivation) {
    color = 'orange';
  } else if (status === CardStatus.activated) {
    color = 'green';
  } else {
    color = 'red';
  }
  return (
    <div className="d-flex-r ai-center">
      <div className={`w-5 h-5 border-radius-5 bg-color-${color} mr-5`} />
      <div className="text-color-white font-size-12">
        {!currentCard.loaded ? (
          '正在获取...'
        ) : currentCard.result?.iccid ? (
          <span className="font-size-14" onClick={() => copyToClipboard(currentCard.result!.iccid!)}>
            {currentCard.result!.iccid!}
          </span>
        ) : (
          '暂无卡片'
        )}
      </div>
      {currentCard.result?.iccid && (
        <img
          className="icon-12 ml-5"
          src={main.pic('icon_copy.png')}
          alt=""
          onClick={() => copyToClipboard(currentCard.result!.iccid!)}
        />
      )}
      <div className="flex-1" />
      <div className="icon-22 ml-20" onClick={() => navigate('/cardProcess', { state: { step: 1 } })}>
        <img className="bh-100" src={main.pic('icon_scan.png')} alt="" />
      </div>
      <div className="icon-22 ml-20" onClick={() => navigate('/setting')}>
        <img className="bh-100" src={main.pic('icon_setting.png')} alt="" />
      </div>
      {/* <div className="icon-22 ml-20">
        <img className="bh-100" src={main.pic('icon_message.png')} alt="" />
      </div> */}
    </div>
  );
}
