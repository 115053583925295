import dayjs from 'dayjs';
import DefaultError from '../components/defaultPage/defaultError';
import DefaultLoading from '../components/defaultPage/defaultLoading';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import { DetailRecord, RecordList } from '../types';

export function rpx(t: number): number {
  return (t / 375) * window.innerWidth;
}
/**
 * 判断是否是手机号
 * @param tel 11位手机号
 * @returns
 */
export function validateTel(tel: string): boolean {
  return /^1[3456789]\d{9}$/.test(tel);
}
/**
 * 判断是否是中文
 * @param str 字符串
 * @returns
 */
export function validateChina(str: string): boolean {
  let chinaReg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
  return chinaReg.test(str);
}

// 延迟执行
export function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

// 解析价格
export function modelParsePrice(data: string | number) {
  var tmp = parseFloat((data ?? '0').toString()) ?? 0;
  return tmp;
}
// 解析流量
export function modelParseFlow(data: string | number) {
  var tmp = parseFloat((data ?? '0').toString()) ?? 0;
  return tmp / 1024 / 1024;
}
// 格式化价格
export function formatNumber(n: number | string | undefined | null) {
  let t = (n ?? 0).toString();
  if (t.includes('.')) {
    return parseFloat(t).toFixed(2);
  }
  return t;
}

interface ListStatusParams {
  list?: RecordList<any>;
  tips?: string;
}
// 列表状态显示
export function dataListStatus({ list, tips }: ListStatusParams) {
  if (!list || list!.loaded !== true) {
    return <DefaultLoading key={Date.now().toLocaleString()} tips={'正在加载...'} />;
  } else if (list!.error) {
    return <DefaultError key={Date.now().toLocaleString()} tips={list!.error} />;
  } else if ((list!.results?.length || 0) <= 0) {
    return <DefaultNoData key={Date.now().toLocaleString()} tips={tips} />;
  }
  return null;
}

interface DetailStatusParams {
  detail: DetailRecord<any>;
  tips?: string;
}
// 详情状态显示
export function dataDetailStatus({ detail, tips }: DetailStatusParams) {
  if (!detail || detail!.loaded !== true) {
    return <DefaultLoading key={Date.now().toLocaleString()} tips={'正在加载...'} />;
  } else if (detail!.error) {
    return <DefaultError key={Date.now().toLocaleString()} tips={detail!.error} />;
  } else if (!detail?.result) {
    return <DefaultNoData key={Date.now().toLocaleString()} tips={tips} />;
  }
  return null;
}
// 解析url参数
export function parseLocationParams(params?: string): { [x: string]: string } {
  if (!params) return {};
  let real = params.startsWith('?') ? params.split('?')[1] : params;
  let _tmpList = real.split('&') || [];
  let p: { [x: string]: string } = {};
  for (let _tmp of _tmpList) {
    let [_k, _v] = _tmp.split('=');
    p[_k] = _v;
  }
  return p;
}
// 深拷贝
export function cloneDeep(obj: any) {
  if (typeof obj !== 'object') return obj;
  return JSON.parse(JSON.stringify(obj));
}
/**
 * 清除对象内的空Key
 * @param _p 数据
 * @returns 数据
 */
export function clearEmptyKey(params: any) {
  let _p = cloneDeep(params);
  Object.keys(_p).forEach((i) => {
    if (!_p[i]) {
      delete _p[i];
    } else if (typeof _p[i] === 'object') {
      if (_p[i] instanceof Object) {
        _p[i] = clearEmptyKey(_p[i]);
      }
      if (!Object.keys(_p[i]).length) {
        delete _p[i];
      }
    }
  });
  return _p;
}

// 格式化时间
export function formatDateDiff(
  minDay: dayjs.Dayjs,
  maxDay: dayjs.Dayjs,
  hideSecond: boolean = false,
  hideMinute: boolean = false
) {
  if (minDay.isAfter(maxDay)) return null;
  var second = maxDay.diff(minDay, 'second');
  var day = Math.floor(second / 3600 / 24);

  var hour = Math.floor((second - day * 3600 * 24) / 3600);
  var min = Math.floor((second - hour * 3600 - day * 3600 * 24) / 60);
  var sec = second % 60;
  var timeStr = '';
  if (day > 0) {
    timeStr += day + '天';
  }
  if (hour > 0) {
    timeStr += (hour < 10 ? `0${hour}` : hour.toString()) + '时';
  }
  if (hideMinute) return timeStr;
  timeStr += (min < 10 ? `0${min}` : min.toString()) + '分';
  if (hideSecond) return timeStr;
  timeStr += (sec < 10 ? `0${sec}` : sec.toString()) + '秒';
  return timeStr;
}
