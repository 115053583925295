import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '../components/appBar';
import Page from '../components/page';
import main from '../main';
import { AppStore, CustomerUrl } from '../types';
import { CardStatus, CustomServiceType } from '../utils/dict';

const newGropMonth = 5;
export default function CustomerPage() {
  const { tel, from } = useLocation().state ?? {};
  const user = useSelector((state: AppStore) => state.user);
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const loginCustomer = useSelector((state: AppStore) => state.loginCustomerUrl);
  const customer = useSelector((state: AppStore) => state.customerUrl);

  let url: string = main.customer;
  if (!user.result?.userId) {
    if (!!loginCustomer.results?.length) {
      let c = loginCustomer.results!.find((i) => i.type === CustomServiceType.visitorGroup);
      if (c && c.serviceUrl) {
        url = c.serviceUrl;
      }
    }
  } else {
    let item: CustomerUrl | undefined;
    let type = '';
    if (customer?.results?.length) {
      if (!currentCard.result?.id) {
        //访客组
        type = CustomServiceType.visitorGroup;
      } else if (currentCard.result!.tagNameList) {
        var cList = currentCard.result!.tagNameList!;
        for (var tag of cList) {
          for (var cs of customer!.results!) {
            if ((cs.tagNameList ?? []).includes(tag)) {
              type = cs.type!;
              item = cs;
              break;
            }
          }
        }
      } else if (currentCard.result?.cardStatus === CardStatus.error) {
        // 异常组
        type = CustomServiceType.errorGroup;
      } else if (
        currentCard.result!.chargeTime &&
        dayjs(currentCard.result!.chargeTime!).diff(dayjs(), 'month') > newGropMonth
      ) {
        // 老用户组
        type = CustomServiceType.oldGroup;
      } else {
        // 新用户组
        type = CustomServiceType.newGroup;
      }
      if (!item) {
        item = customer!.results!.find((i) => i.type === type);
      }
      if (item?.serviceUrl) {
        // 找到的匹配的
        url = item!.serviceUrl!;
      } else {
        // 没有找到匹配的  默认用访客组链接
        item = customer!.results!.find((i) => i.type === CustomServiceType.visitorGroup);
        if (item?.serviceUrl) {
          url = item!.serviceUrl;
        }
      }
    }
  }
  // 没有找到任何客服链接 用天诺的默认链接
  let _url = url;
  if (!_url) {
    _url = main.customer;
  }
  // 容联客服 添加clientId
  if (_url.includes('7moor.com') && !_url.includes('clientId')) {
    if (tel || user.result?.phonenumber) {
      _url += `&clientId=${tel ?? user.result?.phonenumber ?? ''}`;
    }
    if (from) {
      _url += `&urlTitle=${from}`;
    }
  }
  let _tel = tel || user.result?.phonenumber || '';
  let userParams = { uid: _tel ?? '', nickName: _tel };
  _url += `&tel=${_tel}&cardId=${currentCard?.result?.iccid ?? ''}`;
  if (_url.includes('customerFields')) {
    _url = _url.replace('customerFields', `&userParams=${JSON.stringify(userParams)}&customerFields`);
  } else {
    _url += `&userParams=${JSON.stringify(userParams)}`;
  }

  console.log('customerUrl', _url);

  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1 d-flex-c" appBar={<AppBar title="客服" />}>
      <iframe className="flex-1 border-none" title="客服" src={_url} />
    </Page>
  );
}
