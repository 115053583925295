import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiCreateAndEditPwd, apiGetCommonCaptcha } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import { LoadingMask, showToast } from '../components/dialog';
import Page from '../components/page';
import { validateTel } from '../utils/utils';

const time = 60;
export default function PasswordEditPage() {
  const navigate = useNavigate();
  const timerRef = useRef<{ timer?: NodeJS.Timeout; time: number }>({ time: 0 });
  const [showTime, setShowTime] = useState(0);

  const [phone, setPhone] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [pwd, setPwd] = useState('');
  const [npwd, setNpwd] = useState('');

  function startTimer() {
    if (timerRef.current.timer) {
      return;
    }
    timerRef.current.timer = setInterval(() => {
      setShowTime(timerRef.current.time--);
      if (timerRef.current.time <= 0) {
        clearInterval(timerRef.current.timer);
        timerRef.current.timer = undefined;
        return;
      }
    }, 1000);
  }

  async function handleReSend() {
    if (showTime > 0) return;
    if (!validateTel(phone)) {
      return showToast('请输入正确的手机号');
    }
    try {
      LoadingMask.show();
      let res = await apiGetCommonCaptcha(phone);
      let data = res.data;
      if (data.code === 200) {
        timerRef.current.time = time;
        startTimer();
      } else {
        showToast(data.msg || '发送失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '验证码发送失败,请稍后再试');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleSubmit() {
    if (!validateTel(phone)) {
      return showToast('请输入正确的手机号');
    }
    if (!smsCode || smsCode.length !== 4) {
      return showToast('请输入正确的验证码');
    }
    if (!pwd || pwd.length < 6) {
      return showToast('请输入6位密码');
    }
    if (!npwd || npwd.length < 6) {
      return showToast('请再次输入6位密码');
    }
    if (npwd !== pwd) {
      return showToast('两次密码不一致');
    }
    try {
      LoadingMask.show('正在设置...');
      var res = await apiCreateAndEditPwd(pwd, smsCode, phone);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '设置成功');
        navigate(-1);
      } else {
        showToast(data.msg ?? '设置失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '设置失败,请重试');
    } finally {
      LoadingMask.hide();
    }
  }
  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1 overflow-y p-20" appBar={<AppBar title="操作密码设置" />}>
      <div className="font-size-14 mb-10">验证手机号</div>
      <div className="border border-radius-40 p-10 mb-10">
        <input
          className="bw-100"
          type="text"
          placeholder="请输入手机号"
          value={phone}
          maxLength={11}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="border border-radius-40 p-10 d-flex-r ai-center mb-20">
        <input
          className="flex-1 mr-10"
          type="text"
          placeholder="请输入验证码"
          value={smsCode}
          maxLength={4}
          onChange={(e) => setSmsCode(e.target.value)}
        />
        <div className={showTime > 0 ? 'text-color-9' : 'text-color-primary'} onClick={handleReSend}>
          {showTime <= 0 ? '获取验证码' : `${showTime}s`}
        </div>
      </div>
      <div className="font-size-14 mb-10">设置新密码</div>
      <div className="border border-radius-40 p-10 mb-10">
        <input
          className="bw-100"
          type="text"
          placeholder="请输入6位新密码"
          value={pwd}
          maxLength={6}
          onChange={(e) => setPwd(e.target.value)}
        />
      </div>
      <div className="border border-radius-40 p-10 mb-10">
        <input
          className="bw-100"
          type="text"
          placeholder="请再次输入6位新密码"
          value={npwd}
          maxLength={6}
          onChange={(e) => setNpwd(e.target.value)}
        />
      </div>
      <Button className="mt-50" type="primary" size="md" shape="circle" long onClick={handleSubmit}>
        确认修改
      </Button>
    </Page>
  );
}
