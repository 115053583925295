import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiBindCard, apiGetCardLibraryList, apiGetWeChatSignature } from '../../api';
import Button from '../../components/button';
import { LoadingMask, showConfirm, showToast } from '../../components/dialog';
import Radiobox from '../../components/radioBox';
import main, { AppLocalKey } from '../../main';
import { cardRequest } from '../../redux/cardReducer';
import { currentCardRequest } from '../../redux/currentCardReducer';
import { APIProtocolType } from '../../utils/dict';

interface Props {
  onOk: (iccid: string) => void;
}

export default function BindCard({ onOk }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [iccid, setIccid] = useState('');
  const [remark, setRemark] = useState('');
  const [readProtocol, setReadProtocol] = useState(false);

  // 1可以使用  2 未准备 3 客户端不支持
  const [wxReady, setWxReady] = useState(2);
  useEffect(() => {
    wxConfig();
    let scan = localStorage.getItem(AppLocalKey.scanCardNum);
    if (scan) {
      setIccid(scan);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!!iccid) {
      localStorage.setItem(AppLocalKey.scanCardNum, iccid);
    }
  }, [iccid]); // eslint-disable-line

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;
    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let data = res.data;

    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'scanQRCode'], // 必填，需要使用的JS接口列表
      });
    }

    wx.ready(() => {
      wx.checkJsApi({
        //判断当前客户端版本是否支持指定JS接口
        jsApiList: ['scanQRCode'],
        success: function (res: any) {
          // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
          if (res.checkResult.scanQRCode !== true) {
            setWxReady(3);
          } else {
            setWxReady(1);
          }
        },
      });
    });
  }

  function handleScan() {
    if (wxReady === 2) {
      showToast('微信相机正在准备，请稍后再试');
    } else if (wxReady === 3) {
      showToast('抱歉，当前客户端版本不支持扫一扫');
    }
    // @ts-ignore
    let wx = window.wx;
    // 调起企业微信扫一扫接口
    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
      scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res: any) {
        var result: string = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        if (result.includes(',')) {
          result = result.split(',')[1];
        }
        setIccid(result);
      },
      error: function (res: any) {
        if (res.errMsg.indexOf('function_not_exist') > 0) {
          showToast('微信版本过低请升级');
        }
      },
    }); //wx.scanQRcode结束
  }

  async function handleBind() {
    if (!iccid) {
      return showToast('请扫描卡片背面条形码');
    }
    if (!readProtocol) {
      return showToast('请阅读并同意协议');
    }
    LoadingMask.show('正在查询...');
    let age = -1;
    try {
      let res = await apiGetCardLibraryList(iccid!);
      let data = res.data;
      if (data.code === 200) {
        if (data.rows.length > 0) {
          let card = data.rows[0];
          age = card.ageLevel || -1;
          // if (card.operatorType === OperatorType.yiDong) {
          //   age = 18;
          // } else if (card.operatorType === OperatorType.lianTong) {
          //   age = 16;
          // } else if (card.operatorType === OperatorType.dianXin) {
          //   age = 14;
          // }
        } else {
          return showToast('未查询到该卡号');
        }
      }
    } catch (e: any) {
      return showToast('未查询到该卡号');
    } finally {
      LoadingMask.hide();
    }
    if (!!age && age > 0) {
      let modalRes = await showConfirm({
        title: '重要提示',
        waitingTime: 5,
        content: (
          <div className="ph-10">
            因运营商规定未满{age}周岁不能激活该卡请勿充值，避免您的损失，感谢理解。
            <br /> 已满{age}周岁，点击确认继续操作即可。
          </div>
        ),
      });
      if (!modalRes) return;
    }
    LoadingMask.show('正在绑定...');
    try {
      let res = await apiBindCard(iccid, remark, false);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '绑定成功');
        dispatch(cardRequest({ forceRefresh: true }));
        dispatch(currentCardRequest({ forceRefresh: true }));
        onOk(iccid);
        localStorage.removeItem(AppLocalKey.scanCardNum);
      } else {
        showToast(data.msg || '绑定卡号失败');
      }
    } catch (e: any) {
      showToast(e.message || '绑定卡号失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <div className="ml-20 pt-50 d-flex-c ai-center w-335">
      <div className="mb-10">请扫描卡片背面条形码</div>
      <div className="w-280 p-10 d-shadow text-center border-radius-8" onClick={handleScan}>
        <img className="h-126" src={main.pic('pic_scan_card.gif')} alt="" />
      </div>
      <div className="mt-50 border border-radius-4 d-flex-r ai-center p-10 bw-100">
        <input
          type="text"
          className="flex-1"
          placeholder="请扫描卡片背面条形码"
          value={iccid}
          // readOnly
          // onChange={(e) => setIccid(e.target.value)}
          onClick={handleScan}
        />
        <img className="w-14" src={main.pic('icon_scan_xs.png')} alt="" />
      </div>
      <div className="mt-10 border border-radius-4 d-flex-r ai-center p-10 bw-100">
        <input
          type="text"
          className="flex-1"
          placeholder="请输入本人姓名"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </div>
      <Button className="mt-50" type="primary" size="md" shape="circle" long onClick={handleBind}>
        立即绑定
      </Button>
      <div className="d-flex-r mt-20 ai-start">
        <Radiobox value={readProtocol} onChange={() => setReadProtocol(!readProtocol)} />
        <div className="text-color-6 ml-10">
          <span onClick={() => setReadProtocol(!readProtocol)}>
            本人承诺已充分了解本产品的适范围，并保证实名认证信息的真实性，理解并同意
          </span>
          <span
            className="text-color-primary"
            onClick={() => navigate('/protocol', { state: APIProtocolType.userManual })}
          >
            《用户使用手册》
          </span>
        </div>
      </div>
    </div>
  );
}
