import { useState } from 'react';
interface Props {
  onChange: (value: string) => void;
}
export default function CaptchaInput({ onChange }: Props) {
  const [value, setValue] = useState('');

  function handleChange(v: string) {
    setValue(v);
    if (v.length === 4) {
      onChange(v);
    }
  }

  return (
    <div className="d-flex-r jc-between pos-r">
      {[1, 2, 3, 4].map((i) => (
        <div
          key={i}
          className="w-60 h-60 lh-60 font-size-20 text-center d-flex-r ai-center jc-center border-radius-4 bg-color-gray"
        >
          {(value?.length ?? 0) >= i ? '●' : ''}
          {((value?.length ?? 0) === i - 1 || ((value?.length ?? 0) === 4 && i === 4)) && (
            <div className="h-20 w-1 bg-color-9 fang-cursor-pointer"></div>
          )}
        </div>
      ))}
      <input
        style={{ letterSpacing: '-12px' }}
        className="h-60 lh-60 bw-100 font-size-20 pos-a opacity-0"
        value={value}
        pattern="^[0-9]{0,4}$"
        maxLength={4}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
}
