import React from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import { useSelector } from 'react-redux';
import { AppStore, CardPack } from '../types';
import Button from '../components/button';
import { formatNumber } from '../utils/utils';
import dayjs from 'dayjs';
import PackRowList from './components/packRowList';
import { useNavigate } from 'react-router-dom';
import DefaultLoading from '../components/defaultPage/defaultLoading';
import main from '../main';

export default function CardDetailPage() {
  const navigate = useNavigate();
  const currCard = useSelector((state: AppStore) => state.currentCard);

  if (!currCard.result) {
    return (
      <div className="vw-100 vh-100 pos-r">
        <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
        <Page
          className="d-flex-c pos-a"
          contentClassName="pt-50"
          appBar={<AppBar white className="bg-color-t0" title="智能检测" />}
        >
          <DefaultLoading />
        </Page>
      </div>
    );
  }

  let card = currCard?.result;
  // 不限量
  const theMonthUnLimit = (card?.theMonth?.totalFlow ?? 0) > main.showUnlimited;
  const nextMonthUnLimit = (card?.nextMonth?.totalFlow ?? 0) > main.showUnlimited;
  return (
    <div className="vw-100 vh-100 pos-r">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="d-flex-c pos-a"
        contentClassName="flex-1 h-1 overflow-y"
        appBar={<AppBar white className="bg-color-t0" title="卡片详情" />}
      >
        <div className="bg-color-white border-radius-8 d-shadow p-10 mh-10">
          <div className="d-flex-r border-bottom ai-center pb-10">
            <div className="flex-1 font-size-16 font-widget-600">{card?.iccid}</div>
            <Button
              type="primary"
              size="sm"
              shape="circle"
              onClick={() => navigate(`/charge?id=${card.id}`, { state: { canBack: true } })}
            >
              <div className="w-50 text-center">去充值</div>
            </Button>
          </div>
          <div className="d-flex-r pv-10 ai-center">
            <div className="flex-2">
              <div className="font-size-16 font-widget-600">{formatNumber(card?.cardBalance)}</div>
              <div className="text-color-9 mt-5">卡片余额(元)</div>
            </div>
            <div className="flex-2">
              <div className="font-size-16 font-widget-600">{formatNumber(card?.paymentPrice)}</div>
              <div className="text-color-9 mt-5">活动金额(元)</div>
            </div>
            <div className="text-color-primary pl-10" onClick={() => navigate('/coupon')}>
              活动金详情
            </div>
          </div>
        </div>
        {!!card?.theMonth && (
          <div className="bg-color-white border-radius-8 d-shadow p-10 mt-10 mh-10">
            <div className="d-flex-r border-bottom ai-center pb-10">
              <div className="flex-1 font-size-14 font-widget-600">本期套餐</div>
              <div>
                套餐资费:<span className="font-size-18 font-widget-600">{formatNumber(card!.theMonth!.salePrice)}</span>
                元/月
              </div>
            </div>
            <div className="font-size-14 mt-10">【{card!.theMonth!.name}】</div>
            <div className="mt-10 bg-color-primary-t1 border-radius-8 overflow-hidden pt-10">
              <div className="mb-20 ml-10">我的流量</div>
              <div className="d-flex-r ai-end mb-20 ml-10">
                <div className="mr-3 lh-8">剩余</div>
                <div className="font-size-18 text-color-primary lh-16 font-widget-600">
                  {theMonthUnLimit ? '不限量' : formatNumber(card?.theMonth?.surplusFlow)}
                </div>
                {!theMonthUnLimit && <div className="text-color-primary">GB</div>}
                <div className="mr-3 flex-1 text-right lh-8">已用</div>
                <div className="font-size-18 text-color-primary lh-16 font-widget-600">
                  {formatNumber(card?.theMonth?.usedFlow)}
                </div>
                <div className="text-color-primary flex-1 lh-8 ml-3">GB</div>
              </div>
              <div className="p-10 bg-color-primary-t1 text-color-6 font-size-12">
                <span className="mr-10">
                  <span className="font-size-10">全部流量</span>:
                  {theMonthUnLimit ? '不限量' : formatNumber(card.theMonth.totalFlow)}
                  {theMonthUnLimit ? '' : 'GB'}
                </span>
                <span>
                  <span className="font-size-10">有效期</span>:
                  {dayjs(card.theMonth.startEffectTime).format('YYYY/MM/DD')}-
                  {dayjs(card.theMonth.endEffectTime).format('YYYY/MM/DD')}
                </span>
              </div>
            </div>
          </div>
        )}
        {!!card?.nextMonth && (
          <div className="bg-color-white border-radius-8 d-shadow p-10 mt-10 mh-10">
            <div className="d-flex-r border-bottom ai-center pb-10" onClick={() => navigate('/buyPackLog')}>
              <div className="flex-1 font-size-14 font-widget-600">下期生效套餐</div>
              <div className="text-color-primary">订购记录</div>
            </div>
            <div className="pv-10 d-flex-r mt-10">
              <div className="w-60 text-color-9">套餐名称</div>
              <div className="flex-1">【{card.nextMonth.name}】</div>
            </div>
            <div className="pv-10 d-flex-r">
              <div className="w-60 text-color-9">套餐资费</div>
              <div className="flex-1">{formatNumber(card.nextMonth.salePrice)}元/月</div>
            </div>
            <div className="pv-10 d-flex-r">
              <div className="w-60 text-color-9">有效期</div>
              <div className="flex-1">
                {dayjs(card.nextMonth.startEffectTime).format('YYYY/MM/DD')}-
                {dayjs(card.nextMonth.endEffectTime).format('YYYY/MM/DD')}
              </div>
            </div>
            <div className="pv-10 d-flex-r">
              <div className="w-60 text-color-9">套餐流量</div>
              <div className="flex-1">
                {nextMonthUnLimit ? '不限量' : formatNumber(card.nextMonth.totalFlow)}
                {nextMonthUnLimit ? '' : 'GB'}
              </div>
            </div>
          </div>
        )}
        <div className="mt-10">
          <PackRowList
            card={card}
            showBuyedFlag
            hidePackGagType
            onClick={(pack: CardPack, dateType: string) =>
              navigate(`/packOrderConfirm?packId=${pack.id!}&dateType=${dateType}`, { state: { canBack: true } })
            }
          />
        </div>
      </Page>
    </div>
  );
}
