import React, { useEffect, useRef, useState } from 'react';
import { Card } from '../../types';
import { OperatorType } from '../../utils/dict';
import { useNavigate } from 'react-router-dom';
import { apiGetRealUrl, apiGetWeChatSignature, apiUpdateRealTime } from '../../api';
import main from '../../main';
import { copyToClipboard, showBottom, showConfirm, showToast } from '../../components/dialog';
import Button from '../../components/button';
import RealNameGrideDialog from '../../components/realNameGrideDialog';

const ydRealNameStep = [
  {
    pic: 'pic_real_name_m_1.png',
    title: '上传身份证人像面',
  },
  {
    pic: 'pic_real_name_m_2.png',
    title: '上传身份证国徽面',
  },
  {
    pic: 'pic_real_name_m_3.png',
    title: '按照要求拍视频并上传',
  },
  {
    pic: 'pic_real_name_m_4.png',
    title: '实名状态更新有30分钟延迟,请勿重复提交',
  },
];
const ltRealNameStep = [
  {
    pic: 'pic_real_name_1.png',
    title: '点击去实名跳转联通小程序,点击确认登录',
  },
  {
    pic: 'pic_real_name_2.png',
    title: '点击开始认证',
  },
  {
    pic: 'pic_real_name_3.png',
    title: '上传身份证正面',
  },
  {
    pic: 'pic_real_name_4.png',
    title: '上传身份证反面',
  },
  {
    pic: 'pic_real_name_5.png',
    title: '进行人脸识别,完成后点击下一步',
  },
  {
    pic: 'pic_real_name_6.png',
    title: '进行签名认证,完成后点击提交',
  },
  {
    pic: 'pic_real_name_7.png',
    title: '实名状态更新有30分钟延迟,请勿重复提交',
  },
];
const dxRealNameStep = [
  {
    pic: 'pic_real_name_d_1.jpg',
    title: '填写ICCID，手机号，验证码',
  },
  {
    pic: 'pic_real_name_d_2.jpg',
    title: '上传身份证正反面',
  },
  {
    pic: 'pic_real_name_d_3.jpg',
    title: '拍视频认证',
  },
  {
    pic: 'pic_real_name_d_4.jpg',
    title: '认证成功',
  },
];
const gdRealNameStep = [
  {
    pic: 'pic_real_name_gd_1.png',
    title: '登录中国广电营业厅',
  },
  {
    pic: 'pic_real_name_gd_2.png',
    title: '两次输入ICCID后点击确认按钮',
  },
  {
    pic: 'pic_real_name_gd_3.png',
    title: '上传身份证正反面',
  },
  {
    pic: 'pic_real_name_gd_4.png',
    title: '人脸识别前做好准备',
  },
  {
    pic: 'pic_real_name_gd_5.png',
    title: '认证成功',
  },
];

interface Props {
  card: Card;
}
export default function RealName({ card }: Props) {
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [btn, setBtn] = useState<any>();
  const [isCopy, setIsCopy] = useState(false);
  const wxBtnRef = useRef<any>();
  useEffect(() => {
    if (card.operatorType === OperatorType.lianTong) {
      wxConfig();
      if (card.mockCard) {
        getYDUrl();
      }
    } else {
      getYDUrl();
    }
    handleShowRealNameGrideDialog(false);
  }, []); //eslint-disable-line

  function handleRef(e: any) {
    wxBtnRef.current = e;
    wxBtnRef.current?.removeEventListener('ready', readyHandel);
    wxBtnRef.current?.removeEventListener('launch', launchHandel);
    wxBtnRef.current?.removeEventListener('error', errorHandel);
    wxBtnRef.current?.addEventListener('ready', readyHandel);
    wxBtnRef.current?.addEventListener('launch', launchHandel);
    wxBtnRef.current?.addEventListener('error', errorHandel);
  }

  async function launchHandel(e: any) {
    console.log('跳转小程序成功：', e.detail);
    navigate(-1);
    updateRealTime();
  }

  function errorHandel(e: any) {
    console.log('跳转小程序失败：', e.detail);
  }

  function readyHandel() {
    console.log('跳转小程序准备完成');
  }
  // 实名跳转确认
  async function updateRealTime() {
    try {
      await apiUpdateRealTime(card.id!);
    } catch (e) {}
  }

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;

    wx.ready(() => {
      var iccid = card.imsi!.substring(0, card.imsi!.length - 1);
      const wxBtn = (
        // @ts-ignore
        <wx-open-launch-weapp
          ref={handleRef}
          id="launch-btn"
          class="pos-a t-0 r-0 l-0 b-0 z-10"
          username={main.unionMiniprogram}
          path={`pages/login/index?iccid=${iccid}`}
        >
          <script type="text/wxtag-template">
            <div
              style={{ width: '100vw', height: '12.8vw', background: 'transparent', display: 'block', border: 'none' }}
            />
          </script>
          {/* @ts-ignore */}
        </wx-open-launch-weapp>
      );
      setBtn(wxBtn);
    });

    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let data = res.data;
    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: [], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'],
      });
    }
  }
  async function getYDUrl() {
    // 模拟实名
    //     https://real.sim10010.com/gd-start?cardId=xxxxx 广电实名
    // https://real.sim10010.com/lt-start?cardId=xxxxx 联通实名
    // https://real.sim10010.com/yd-start?cardId=xxxxx 移动实名

    var _url = '';
    if (!!card.mockCard) {
      let t = card.operatorType;
      if (card.operatorType === OperatorType.dianXin) {
        t = 'gd';
      }
      _url = `https://real.sim10010.com/${t}-start?cardId=${card.id}`;
    } else {
      try {
        var res = await apiGetRealUrl(card.id!);
        var data = res.data;
        if (data.code === 200) {
          _url = data.data;
        } else {
          _url = data.msg ?? '移动官方系统升级，暂时无法获取';
          showToast(_url);
        }
      } catch (e: any) {
        _url = '移动官方系统升级，暂时无法获取';
        showToast(_url);
      }
    }
    setUrl(_url);
  }

  function handleCopyUrl() {
    if (!url || !url.startsWith('http')) {
      showConfirm({
        title: '提示',
        content: url ?? '官方系统升级，暂时无法获取实名链接',
        hideCancelBtn: true,
        confirmBtnText: '知道了',
      });
    } else {
      copyToClipboard(url);
      updateRealTime();
    }
  }

  function handleCopyIccid() {
    let iccid = card.imsi!;

    copyToClipboard(iccid, () => {
      showToast('复制20位ICCID成功！');
    });
    setIsCopy(true);
  }

  function handleRealName() {
    if (card.operatorType === OperatorType.guangDian && !isCopy) {
      return showToast('请先复制实名ICCID');
    }
    if (!url || !url.startsWith('http')) {
      showConfirm({
        title: '提示',
        content: url ?? '官方系统升级，暂时无法获取实名链接',
        hideCancelBtn: true,
        confirmBtnText: '知道了',
      });
    } else {
      window.open(url);
      updateRealTime();
    }
  }

  function handleShowRealNameGrideDialog(disableTimer: boolean) {
    let tips: any[] = [];
    tips = gdRealNameStep;
    if (card.operatorType === OperatorType.guangDian) {
      tips = gdRealNameStep;
    } else if (card.operatorType === OperatorType.yiDong) {
      tips = ydRealNameStep;
    } else if (card.operatorType === OperatorType.lianTong) {
      tips = ltRealNameStep;
    } else if (card.operatorType === OperatorType.dianXin) {
      tips = dxRealNameStep;
    }
    showBottom({
      buildContent: (onOk) => <RealNameGrideDialog onOk={onOk} steps={tips} disableTimer={disableTimer} />,
    });
  }

  let age = '';
  if (card.operatorType === OperatorType.guangDian) {
    age = '14';
  } else if (card.operatorType === OperatorType.yiDong) {
    age = '18';
  } else {
    age = '16';
  }

  return (
    <>
      <div className="text-center">
        <img className="w-100" src={main.pic('pic_real_name.png')} alt="" />
        <div className="font-size-14 font-widget-600">实名认证</div>
      </div>
      {card.operatorType === OperatorType.guangDian || card.operatorType === OperatorType.dianXin ? (
        <div className="p-20 text-color-6 flex-1 h-1 overflow-y font-size-14">
          <div className="mb-20">运营商的实名温馨提示：</div>
          <div className="mb-5">
            1、实名认证需要<span className="text-color-red">先复制ICCID,再去实名链接中粘贴.</span>
          </div>
          <div className="mb-5">
            2、本卡实名要求<span className="text-color-red">年满{age}周岁以上</span>，未达到年龄要求可能无法实名。
          </div>
          <div className="mb-5">3、本卡认证无一证五户要求，没有运营商黑名单限制。</div>
          <div className="mb-5">
            4、该实名链接为{OperatorType.getTypeStr(card.operatorType)}
            官方指定认证通道，我们不收集，也无权查询您个人认证信息，请知悉并放心操作。
          </div>
          <div className="mb-5">
            5、请根据要求上传身份证正反面，务必现拍证件，拍照时要求无反光和遮挡水印，保证文字清晰可见。
          </div>
          <div className="mb-5">
            6、进行人脸认证时，露出完整面部，背景尽量纯色，周边环境安静下完成。(录屏时长3-5秒为宜)
          </div>
          <div className="mb-5">7、收到认证成功提示，可将卡放入设备尝试用网。</div>
          <div className="mb-5">8、激活结果可能存在延时，若已经实名但结果未更新，请稍后刷新再次查询。</div>
        </div>
      ) : (
        <div className="p-20 text-color-6 flex-1 h-1 overflow-y font-size-14">
          <div className="mb-20">运营商的实名温馨提示：</div>

          <div className="mb-5">
            1、本卡实名要求<span className="text-color-red">年满{age}周岁以上</span>，未达到年龄要求可能无法实名。
          </div>
          <div className="mb-5">2、本卡认证无一证五户要求，没有运营商黑名单限制。</div>
          <div className="mb-5">
            3、该实名链接为{OperatorType.getTypeStr(card.operatorType)}
            官方指定认证通道，我们不收集，也无权查询您个人认证信息，请知悉并放心操作。
          </div>
          <div className="mb-5">
            4、请根据要求上传身份证正反面，务必现拍证件，拍照时要求无反光和遮挡水印，保证文字清晰可见。
          </div>
          <div className="mb-5">
            5、进行人脸认证时，露出完整面部，背景尽量纯色，周边环境安静下完成。(录屏时长3-5秒为宜)
          </div>
          <div className="mb-5">6、收到认证成功提示，可将卡放入设备尝试用网。</div>
          <div className="mb-5">7、激活结果可能存在延时，若已经实名但结果未更新，请稍后刷新再次查询。</div>
        </div>
      )}
      <div className="ph-20 pt-10 pb-20">
        {(card.operatorType === OperatorType.guangDian || card.operatorType === OperatorType.dianXin) && (
          <div className="d-flex-r ai-center mb-10" onClick={handleCopyIccid}>
            <div className="flex-1 font-size-14">
              <div>第一步：复制20位ICCID</div>
              <div className="text-color-primary">{card.imsi}</div>
            </div>
            <Button type="primary" size="sm" shape="round">
              复制20位ICCID
            </Button>
          </div>
        )}
        {(card.operatorType === OperatorType.guangDian || card.operatorType === OperatorType.dianXin) && (
          <div onClick={isCopy ? undefined : () => showToast('去实名需要先复制20位ICCID')}>
            <Button
              className="mb-10 pos-r"
              type={isCopy ? 'primary' : 'gray'}
              size="md"
              shape="circle"
              disabled={!isCopy}
              long
              onClick={handleRealName}
            >
              第二步：去实名
            </Button>
          </div>
        )}
        {card.operatorType !== OperatorType.guangDian && card.operatorType !== OperatorType.dianXin && (
          <Button
            className="mb-20 pos-r"
            type="primary"
            size="md"
            shape="circle"
            long
            onClick={card.operatorType === OperatorType.lianTong && !card.mockCard ? undefined : handleRealName}
          >
            {card.operatorType === OperatorType.lianTong && !card.mockCard ? btn : undefined}去实名
          </Button>
        )}
        {card.operatorType !== OperatorType.lianTong && (
          <Button className="mb-10 pos-r" type="primary" size="md" shape="circle" long outline onClick={handleCopyUrl}>
            无法打开？复制链接到浏览器打开
          </Button>
        )}
        <div className="text-color-primary text-center" onClick={() => handleShowRealNameGrideDialog(true)}>
          查看演示示例
        </div>
      </div>
    </>
  );
}
