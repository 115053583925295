import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiEditCard, apiRemoveCard, apiUpdateCardTransferLogStatus } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import { LoadingMask, showConfirm, showCustom, showToast } from '../components/dialog';
import Page from '../components/page';
import main, { AppLocalKey } from '../main';
import { cardRequest } from '../redux/cardReducer';
import { cardTransferRequest } from '../redux/cardTransferReducer';
import { currentCardRequest } from '../redux/currentCardReducer';
import { setTheme } from '../redux/themeReducer';
import { AppStore, Card } from '../types';
import { CardStatus, CardTransferStatus, OperatorType } from '../utils/dict';
import { dataListStatus, formatNumber } from '../utils/utils';
import ScanCardDialog from './transferCardComponents/scanCardDialog';

enum ItemMenuAction {
  /** 编辑 */
  edit = 'edit',
  /** 设为默认卡 */
  setDefault = 'setDefault',
  /** 申请换卡 */
  applySwitchCard = 'applySwitchCard',
  /** 转卡 */
  transferCard = 'transferCard',
  /** 解绑 */
  deleteCard = 'deleteCard',
}

export default function CardListPage() {
  const user = useSelector((state: AppStore) => state.user);
  const cardList = useSelector((state: AppStore) => state.card);
  const configList = useSelector((state: AppStore) => state.systemConfig);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(cardRequest());
    }
  }, [user]); // eslint-disable-line

  /**
   * 编辑卡信息
   * @param id
   * @param isDefault
   * @param remark
   */
  async function handleApiEditCard(id: string, remark: string, isDefault?: boolean) {
    try {
      LoadingMask.show('正在修改...');
      let res = await apiEditCard(id, remark, isDefault);
      let data = res.data;
      if (data.code === 200) {
        showToast('修改成功');
        dispatch(cardRequest({ forceRefresh: true }));
        if (isDefault) {
          dispatch(currentCardRequest({ forceRefresh: true }));
        }
      } else {
        showToast(data.msg ?? '修改失败');
      }
    } finally {
      LoadingMask.hide();
    }
  }
  /**
   * 设置默认卡
   * @param item
   * @returns
   */
  async function handleSetDefault(item: Card) {
    if (!item.ifDefault) {
      await handleApiEditCard(item.id!, item.userRemark ?? '', true);
      dispatch(setTheme({ type: item.operatorType! }));
    }
    // let res = await showConfirm({ title: '提示', content: '确定要将该卡设置为默认卡吗？' });
    // if (!res) return;
    // await handleApiEditCard(item.id!, item.userRemark ?? '', true);
    navigate(-1);
  }

  /**
   * 修改卡备注
   * @param item
   * @returns
   */
  async function handleEditCard(item: Card) {
    let remark = item.userRemark ?? '';
    let res = await showConfirm({
      title: '编辑卡片',
      content: (
        <EditCardDialogContent
          card={item}
          onChange={(_remark) => {
            remark = _remark;
          }}
        />
      ),
    });
    if (!res) return;
    if (remark === item.userRemark) return;
    handleApiEditCard(item.id!, remark);
  }

  // 解绑
  async function handleDeleteCard(card: Card) {
    let modalRes = await showConfirm({ title: '提示', content: '解绑后信息和余额无法恢复，\n是否继续解绑' });
    if (modalRes !== 1) return;
    try {
      LoadingMask.show('正在解绑...');
      let res = await apiRemoveCard(card.id!);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '解绑成功');
        dispatch(cardRequest({ forceRefresh: true }));
        if (card.ifDefault) {
          dispatch(currentCardRequest({ forceRefresh: true }));
        }
      } else {
        showToast(data.msg ?? '解绑失败');
      }
    } catch (e: any) {
      showToast(e.message || '解绑失败');
    } finally {
      LoadingMask.hide();
    }
  }
  /**
   * 扫码换卡
   * @param item
   */
  async function handleTransferCard(item: Card) {
    let modalRes = await showCustom({ buildContent: (onOk) => <ScanCardDialog onOk={onOk} title="请识别新卡号" /> });
    if (modalRes.res === 0) return;
    if (modalRes.res !== 1) return showToast(modalRes.msg);
    let niccid = modalRes.msg;
    try {
      LoadingMask.show('正在转卡...');
      let res = await apiUpdateCardTransferLogStatus(item!.transferId!, CardTransferStatus.completed, niccid as string);
      if (res.data.code === 200) {
        showToast(res.data.msg ?? '转卡成功');
        dispatch(cardRequest({ forceRefresh: true }));
        dispatch(cardTransferRequest({ forceRefresh: true }));
        if (item.ifDefault) {
          dispatch(currentCardRequest({ forceRefresh: true }));
        }
      } else {
        showToast(res.data.msg || '转卡失败');
      }
    } catch (e: any) {
      showToast(e.message || '转卡失败');
    } finally {
      LoadingMask.hide();
    }
  }

  /**
   * 卡菜单点击
   * @param item
   * @param action
   */
  function handleItemMenuClick(item: Card, action: ItemMenuAction) {
    if (action === ItemMenuAction.edit) {
      handleEditCard(item);
    } else if (action === ItemMenuAction.deleteCard) {
      handleDeleteCard(item);
    } else if (action === ItemMenuAction.applySwitchCard) {
      navigate('/applySwitchCard', { state: { cardId: item.id, iccid: item.iccid } });
    } else if (action === ItemMenuAction.setDefault) {
      handleSetDefault(item);
    } else if (action === ItemMenuAction.transferCard) {
      handleTransferCard(item);
    }
  }

  let el = dataListStatus({ list: cardList });
  if (el) {
    return (
      <Page
        className="d-flex-c bg-color-gray"
        contentClassName="flex-1 pt-100 d-flex-c ai-center"
        appBar={<AppBar title="卡片管理" className="bg-color-gray" />}
      >
        <div className="flex-1">{el}</div>
        <div className="p-20 bw-100">
          <Button
            type="primary"
            shape="circle"
            size="md"
            long
            onClick={() => navigate('/cardProcess', { state: { step: 1 } })}
          >
            扫码绑卡
          </Button>
        </div>
      </Page>
    );
  }
  let _list = cardList.results!;
  if (!!searchValue) {
    _list = cardList.results!.filter((i) => i.iccid!.includes(searchValue));
  }

  // 2226 【优速流量】切换卡片优化 默认卡放到第一位

  let isDefault: Card[] = [];
  let isNotDefault: Card[] = [];
  _list.forEach((i) => {
    if (i.ifDefault) {
      isDefault.push(i);
    } else {
      isNotDefault.push(i);
    }
  });
  _list = [...isDefault, ...isNotDefault];
  // 2226 -----------------------------------

  function renderItem(item: Card, index: number) {
    // 0 不能申请 1 可申请 2 可换卡
    var _request = 0;
    if (item.cardStatus !== CardStatus.waitactivation && !item.transferId) {
      var disableSwapCard = (item.tagNames ?? '').includes(AppLocalKey.disableSwapCard);
      var config = (configList?.results ?? []).find((i) => i.configKey === AppLocalKey.cardListRequestSwapCard);
      if (!disableSwapCard && config?.configValue === 'true') {
        _request = 1;
      }
    } else if (item.transferStatus === CardTransferStatus.waitTransferCard) {
      _request = 2;
    }
    let menus = [{ label: '编辑', value: ItemMenuAction.edit }];
    if (!item.ifDefault) {
      // menus.push({ label: '设为默认', value: ItemMenuAction.setDefault });
    }
    if (_request === 1) {
      menus.push({ label: '申请换卡', value: ItemMenuAction.applySwitchCard });
    }
    if (_request === 2) {
      menus.push({ label: '转卡', value: ItemMenuAction.transferCard });
    }
    if (!item.chargeTime) {
      menus.push({ label: '解绑', value: ItemMenuAction.deleteCard });
    }

    // 是否显示不限量
    var isUnlimit = (item.theMonth?.totalFlow ?? 0) > main.showUnlimited;
    return (
      <div key={item.id! + index} className="bg-color-white mb-10 p-10 border-radius-6 pos-r">
        <img
          onClick={() => handleItemMenuClick(item, ItemMenuAction.setDefault)}
          className="w-48 r-20 t-10 pos-a z-0"
          src={main.pic(`${OperatorType.getTypeIcon(item.operatorType)}.png`)}
          alt=""
        />
        <div
          className="d-flex-r ai-center pb-10 border-bottom"
          onClick={() => handleItemMenuClick(item, ItemMenuAction.setDefault)}
        >
          <img
            className="icon-36 mr-10"
            src={main.pic(
              item.cardStatus === CardStatus.activated ? 'icon_card_status_active.png' : 'icon_card_status_normal.png',
              item.cardStatus === CardStatus.activated
            )}
            alt=""
          />
          <div className="flex-1">
            <div className=" d-flex-r ai-center">
              <div className="font-size-16 font-widget-600 mr-10">{item.iccid}</div>
              {item.ifDefault && (
                <span className="font-size-12 text-color-white pv-2 ph-10 border-radius-10 bg-color-red mr-10">
                  当前
                </span>
              )}
              <span className="font-size-12 text-color-9">{item.userRemark}</span>
            </div>
            <div className="mt-10 text-color-9">
              <span>{CardStatus.getTypeStr(item.cardStatus)}</span>
              <span className="ml-5 mr-5">|</span>
              <span>余额:{formatNumber(item.userTotalBalance)}元</span>
              <span className="ml-5 mr-5">|</span>
              {isUnlimit ? (
                <span>剩余流量:不限量</span>
              ) : (
                <span>剩余流量:{formatNumber(item.theMonth?.surplusFlow)}GB</span>
              )}
            </div>
          </div>
        </div>
        <div className="pt-10 d-flex-r ai-center">
          {/* <PopMenu className="flex-1" onClick={(v) => handleItemMenuClick(item, v as ItemMenuAction)} options={menus}>
            <div className="text-color-9">更多</div>
          </PopMenu> */}
          {}
          <div className="d-flex-r">
            {menus.map((i, idx) => (
              <div key={i.value + idx} className="mr-20" onClick={() => handleItemMenuClick(item, i.value)}>
                {i.label}
              </div>
            ))}
          </div>
          <div className="flex-1"></div>
          {item.cardStatus === CardStatus.waitactivation ? (
            <Button
              type="primary"
              shape="circle"
              size="xs"
              onClick={() => navigate('/cardProcess', { state: { step: 2, iccid: item.iccid } })}
            >
              <div className="w-60">激活</div>
            </Button>
          ) : item.cardStatus === CardStatus.preRealName ? (
            <Button
              type="primary"
              shape="circle"
              size="xs"
              onClick={() => navigate('/cardProcess', { state: { step: 3, iccid: item.iccid } })}
            >
              <div className="w-60">去实名</div>
            </Button>
          ) : item.cardStatus === CardStatus.activated ? (
            <Button
              type="primary"
              shape="circle"
              size="xs"
              onClick={() => navigate(`/charge?id=${item.id}`, { state: { canBack: true } })}
            >
              <div className="w-60">充值</div>
            </Button>
          ) : (
            <div className="action-btn ml-3" />
          )}
        </div>
      </div>
    );
  }

  return (
    <Page
      className="bg-color-gray d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c p-20"
      appBar={<AppBar title="卡片管理" className="bg-color-gray" />}
    >
      <div className="d-flex-r bg-color-white h-36 ai-center border-radius-36 pl-20">
        <img className="h-16 mr-10" src={main.pic('icon_search.png')} alt="" />
        <input
          className="flex-1"
          type="text"
          value={searchValue}
          placeholder="请输入卡号进行搜索"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {!!searchValue && (
          <div className="ph-10 d-flex-r ai-center" onClick={() => setSearchValue('')}>
            <img className="w-14" src={main.pic('icon_close_black.png')} alt="" />
          </div>
        )}
      </div>
      <div className="flex-1 h-1 overflow-y mt-10 border-radius-8">
        {_list.map((item, index) => renderItem(item, index))}
      </div>
      <div className="pt-20">
        <Button
          type="primary"
          shape="circle"
          size="md"
          long
          onClick={() => navigate('/cardProcess', { state: { step: 1 } })}
        >
          扫码绑卡
        </Button>
      </div>
    </Page>
  );
}
interface EditCardDialogContentProps {
  card: Card;
  onChange: (remark: string) => void;
}
function EditCardDialogContent({ card, onChange }: EditCardDialogContentProps) {
  const [remark, setRemark] = useState(card.userRemark ?? '');

  function handleChangeRemark(v: string) {
    setRemark(v);
    onChange(v);
  }

  return (
    <div className="pt-20 ph-20">
      <div className="border border-radius-4 h-32 lh-32 pl-10 bg-color-gray text-left">{card.iccid}</div>
      <div className="border border-radius-4 h-32 d-flex-r ai-center jc-center ph-10 mt-16">
        <input
          className="bw-100"
          type="text"
          placeholder="备注"
          defaultValue={remark}
          onChange={(e) => handleChangeRemark(e.target.value)}
        />
      </div>
    </div>
  );
}
