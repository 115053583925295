import axios, { AxiosInstance } from 'axios';
import { showToast } from './components/dialog';
import { logoutRequest } from './redux/logoutReducer';
import store from './redux/store';
import { delay } from './utils/utils';

// @ts-ignore
const defaultValue = window.appConfig;
export class Main {
  static lt: string = 'lt';
  static yd: string = 'yd';
  static dx: string = 'dx';
  static gd: string = 'gd';
  static def: string = 'def';
  /**  当前主题色 */
  currentTheme: string = Main.yd;
  currentThemeForce: boolean = false;

  /**  移动主色 */
  ydColor: string = '#3D85FF';
  /**  联通主色 */
  ltColor: string = '#EC4A3B';
  /**  电信主色 */
  dxColor: string = '#205686';
  /**  广电主色 */
  gdColor: string = '#205686';

  api: AxiosInstance = axios.create({
    baseURL: defaultValue.url,
    timeout: 120000,
  });
  openId: string = '';
  platform = '';
  /**  是否ios14 签名有问题 */
  isIos14: boolean = false;
  /**  是ios14以上需要保存初始url */
  enterUrl: string = '';
  /**  应用名称 */
  appName = defaultValue.appName;
  /**  部门id */
  deptId = defaultValue.deptId;
  /**  应用id 公众号 */
  appId = defaultValue.appId;
  tel400 = defaultValue.tel400;
  /**  默认客服链接 */
  customer = defaultValue.customer;
  /**  配置了此项 支付时没有openID也不拦截，可测试余额支付 */
  isDev = !!defaultValue.isDev;
  /**  联通实名小程序 */
  unionMiniprogram = 'gh_5eac63f4d504';
  /**  登录token */
  loginToken?: string = '';
  //==============================================

  /** / 套餐流量大于10000时显示不限量 */
  showUnlimited: number = 10000;
  //============================================== 强制跳转
  /**  绑定卡号数量 */
  bindCardNum: number = 0;
  /**  绑定卡号数量是否加载完成 */
  bindCardNumLoaded: boolean = false;
  /** 是否跳转过绑卡页面 */
  jumpBindCard = false;
  /** 是否强制跳转过活动 */
  jumpToActivity: { [x: string]: boolean } = {};
  /** 是否强制跳转过买套餐 */
  jumpToBuyPack: { [x: string]: boolean } = {};
  /** 是否强制跳转过实名 */
  jumpToRealName: { [x: string]: boolean } = {};
  /** 是否跳转过直充活动页面 */
  jumpToCharge: { [x: string]: boolean } = {};
  /** 是否跳转过申请换卡页面 */
  jumpToTransfer: { [x: string]: boolean } = {};
  /** 套餐过期弹窗是否显示过 */
  packExpiredShowModal: { [x: string]: boolean } = {};
  /** 流量不足提醒 */
  flowLack: { [x: string]: boolean } = {};
  /** 流量超出提醒 */
  flowExceed: { [x: string]: boolean } = {};
  /** 标签弹窗是否显示过 {[iccid+config.configKey]:boolean} */
  tagDiaowShow: { [x: string]: boolean } = {};
  /** 停机原因弹窗是否显示过 */
  stopDialog: { [x: string]: boolean } = {};
  //===============================================
  /** 登录时输入的手机号 */
  loginPhone: string = '';
  /** 悬浮客服按钮位置是否写入  */
  customerPositionWrite: boolean = false;
  /** 悬浮客服按钮位置 左  */
  customerLeft: number = 0;
  /** 悬浮客服按钮位置 上  */
  customerTop: number = 0;
  //===============================================
  init() {
    let ua = window.navigator.userAgent.toLowerCase();
    const ver = ua.match(/cpu iphone os (.*?) like mac os/);
    this.isIos14 = ver ? Number(ver?.[1].split('_')[0]) >= 14 : false;
    let token = localStorage.getItem(AppLocalKey.loginToken);
    if (token) {
      this.loginToken = token;
      main.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    this.api.interceptors.response.use(
      (res) => {
        const code = res.data.code;
        if (code === 401 && main.loginToken) {
          showToast('登录已过期，请重新登录');
          store.dispatch(logoutRequest());
          delay(1500).then(() => {
            window.location.href = '/';
          });
        }
        return res;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  getThemeColor() {
    let type = this.currentTheme;
    return type === Main.lt
      ? this.ltColor
      : type === Main.yd
      ? this.ydColor
      : type === Main.dx
      ? this.dxColor
      : type === Main.gd
      ? this.gdColor
      : this.ydColor;
  }
  setTheme(type: string, force?: boolean) {
    if (this.currentThemeForce && !force) return;
    if (force) {
      this.currentThemeForce = true;
    }
    this.currentTheme = type;
    let color =
      type === Main.lt
        ? this.ltColor
        : type === Main.yd
        ? this.ydColor
        : type === Main.dx
        ? this.dxColor
        : type === Main.gd
        ? this.gdColor
        : this.ydColor;
    let title =
      type === Main.lt
        ? '联通'
        : type === Main.yd
        ? '移动'
        : type === Main.gd
        ? '广电'
        : type === Main.dx
        ? '电信'
        : '上网卡';
    window.document.title = `${title}网厅`;
    window.document.body.setAttribute(
      'style',
      `
    --color-primary:${color};
    --color-primary-t0:${color}00;
    --color-primary-t1:${color}1a;
    --color-primary-t2:${color}33;
    --color-primary-t3:${color}4d;
    --color-primary-t4:${color}66;
    --color-primary-t5:${color}80;
    --color-primary-t6:${color}99;
    --color-primary-t7:${color}b3;
    --color-primary-t8:${color}cc;
    --color-primary-t9:${color}e6;`
    );
  }

  /**
   * 获取图片完整地址
   * @param name 图片名加后缀
   * @param isTheme 是否主题图片
   * @returns
   */
  pic(name: string, isTheme?: boolean) {
    if (!isTheme) {
      return `/pic/${name}`;
    }
    return `/pic/${this.currentTheme === Main.def ? Main.yd : this.currentTheme}/${name}`;
  }
}
const main = new Main();
export default main;

export class AppLocalKey {
  /** 登录 token */
  static loginToken = 'loginToken';
  /** 记住登录账号 */
  static loginUsername = 'loginUsername';
  /** 是否展示申请换卡入口 全局参数配置 */
  static cardListRequestSwapCard = 'cardListRequestSwapCard';
  /** 禁止换卡标签，有些标签内容的卡列表上都不显示换卡 */
  static disableSwapCard = '禁止换卡';
  /** 选择地址 选地址时把地址存到缓存里，并后退页面到用地址的页面，再从缓存里读出来 */
  static selectAddress = 'selectAddress';
  /** 验证码页面跳转 再回来的时候不要再发验证码 */
  static codePageToNext = 'codePageToNext';
  /** 验证码页面跳走时的倒计时保留，再回来时间继续 */
  static codePageTime = 'codePageTime';
  /// 无预存变有预存标签，只是弹窗不跳转
  static renewal = '预';
  /** 扫码页面留存卡号 */
  static scanCardNum = 'scanCardNum';
  /** 历史登录手机号 */
  static historyPhone = 'historyPhone';
}
